.header {
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  background: #fdf;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transform: translateZ(10px);
}
.header form {
  flex: 1;
}
