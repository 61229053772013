.participants {
  top: 50px;
  bottom: 0;
  left: 0;
  width: 180px;
  position: fixed;
  border-right: 1px solid #f2f2f2;
  user-select: none;
  overflow-y: auto;
}
.participants>ul>li {
  font-size: 14px;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
.participants>ul>li:hover {
  background: #fef;
}
.participants>ul>li:hover .remove {
  visibility: visible;
}
.participants>ul>li img,
.participants>ul>li span {
  vertical-align: middle;
}
.participants>ul>li img {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  margin-right: 5px;
  border: 2px solid #fcf;
  overflow: hidden;
}
.participants>ul>li span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.participants>ul>li .remove {
  visibility: hidden;
  width: 12px;
  height: 12px;
  opacity: 0.3;
  position: relative;
  vertical-align: middle;
  display: inline-block;
}
.participants>ul>li .remove:hover {
  opacity: 1;
}
.participants>ul>li .remove::before,
.participants>ul>li .remove::after {
  position: absolute;
  left: 5px;
  content: ' ';
  height: 12px;
  width: 2px;
  background-color: #333;
}
.participants>ul>li .remove::before {
  transform: rotate(45deg);
}
.participants>ul>li .remove::after {
  transform: rotate(-45deg);
}
