.killer {
  display: inline-flex;
  font-size: 18px;
  border: 2px solid #eee;
  border-right: 0;
  width: 40px;
  height: 28px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fbf;
  cursor: pointer;
  background: #fff;
}
.killer.red {
  color: #f00;
}
.killer.black {
  color: #000;
}
.killer.blue {
  color: #66f;
}
.killer:hover {
  text-shadow: 0 0 5px currentColor;
  background: #fef;
}
.killer.disabled {
  color: #ddd;
  pointer-events: none;
}
