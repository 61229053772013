.winner {
  user-select: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: rgba(0,0,0,0.8);
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
  z-index: 1000;
  transform: translateZ(10px);
  padding-top: 50px;
  padding-left: 180px;
}
.winner .poker.red {
  color: #f00;
}
.winner .poker.black {
  color: #000;
}
.winner .avatar {
  width: 200px;
  height: 200px;
  border: 3px solid #fdf;
  margin-bottom: 40px;
  box-shadow: 0 0 10px #fdf;
  background: #fff;
  border-radius: 20px;
  animation: shine-box 2s infinite;
}
.winner .name {
  animation: shine-text 2s infinite;
}
.winner .poker {
  font-size: 28px;
  text-shadow: 0 0 5px #fff;
}
.winner .avatar,
.winner .name,
.winner .poker {
  margin-bottom: 40px;
}
.winner .controls {
  text-align: center;
}
.winner .controls .control {
  font-size: 18px;
  border: 2px solid currentColor;
  color: #e9e;
  box-shadow: 0 0 3px #fff;
  background: #fff;
  display: inline-flex;
  width: 150px;
  height: 44px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  margin: 8px 15px;
}
.winner .controls .control:hover {
  box-shadow: 0 0 10px #fff;
}
@media screen and (max-width: 1024px) {
  .winner {
    font-size: 16px;
  }
  .winner .avatar,
  .winner .name,
  .winner .poker {
    margin-bottom: 15px;
  }
}
@-moz-keyframes shine-box {
  0% {
    box-shadow: 0 0 10px #fdf;
  }
  50% {
    box-shadow: 0 0 20px #f9f;
  }
  100% {
    box-shadow: 0 0 10px #fdf;
  }
}
@-webkit-keyframes shine-box {
  0% {
    box-shadow: 0 0 10px #fdf;
  }
  50% {
    box-shadow: 0 0 20px #f9f;
  }
  100% {
    box-shadow: 0 0 10px #fdf;
  }
}
@-o-keyframes shine-box {
  0% {
    box-shadow: 0 0 10px #fdf;
  }
  50% {
    box-shadow: 0 0 20px #f9f;
  }
  100% {
    box-shadow: 0 0 10px #fdf;
  }
}
@keyframes shine-box {
  0% {
    box-shadow: 0 0 10px #fdf;
  }
  50% {
    box-shadow: 0 0 20px #f9f;
  }
  100% {
    box-shadow: 0 0 10px #fdf;
  }
}
@-moz-keyframes shine-text {
  0% {
    text-shadow: 0 0 10px #fdf;
  }
  50% {
    text-shadow: 0 0 20px #f9f;
  }
  100% {
    text-shadow: 0 0 10px #fdf;
  }
}
@-webkit-keyframes shine-text {
  0% {
    text-shadow: 0 0 10px #fdf;
  }
  50% {
    text-shadow: 0 0 20px #f9f;
  }
  100% {
    text-shadow: 0 0 10px #fdf;
  }
}
@-o-keyframes shine-text {
  0% {
    text-shadow: 0 0 10px #fdf;
  }
  50% {
    text-shadow: 0 0 20px #f9f;
  }
  100% {
    text-shadow: 0 0 10px #fdf;
  }
}
@keyframes shine-text {
  0% {
    text-shadow: 0 0 10px #fdf;
  }
  50% {
    text-shadow: 0 0 20px #f9f;
  }
  100% {
    text-shadow: 0 0 10px #fdf;
  }
}
