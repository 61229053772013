html {
  padding-top: 50px !important;
}
.playground {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.toolbar {
  user-select: none;
  overflow: auto;
  background: #f5f5f5;
}
.main {
  margin-left: 180px;
  position: relative;
}
