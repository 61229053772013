.poker-card {
  margin: 10px;
  width: 130px;
  height: 200px;
  box-sizing: border-box;
  transition: all 0.3s;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 6px #ccc;
  display: inline-block;
  transform-style: preserve-3d;
}
.poker-card.unselected {
  transform: rotateY(180deg);
}
.poker-card.unselected:hover {
  transform: scale(1.1) rotateY(180deg);
}
.poker-card.win {
  animation: win 2s infinite;
}
.poker-card.show {
  transform: rotateY(0deg);
}
.poker-card.dead .forward::after {
  content: '出局';
  position: absolute;
  font-size: 24px;
  border: 3px solid currentColor;
  transform: rotate(35deg);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  height: 50px;
  text-align: center;
  color: #f66;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px currentColor inset, 0 0 3px currentColor;
  text-shadow: 0 0 3px currentColor;
  border-radius: 10px;
}
.poker-card .forward,
.poker-card .backward {
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.poker-card .forward {
  transform: translateZ(1px);
  border: 1px solid #ccc;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.poker-card .forward .sign {
  font-size: 24px;
  text-indent: 14px;
  text-align: left;
  width: 100%;
}
.poker-card .forward .sign.red {
  color: #f00;
}
.poker-card .forward .sign.black {
  color: #000;
}
.poker-card .backward {
  transform: rotateY(180deg);
  border: 1px solid #ccc;
}
.poker-card .backward .backward-background {
  border-radius: 10px;
  border: 6px double #fcf;
  width: 100%;
  height: 100%;
  background-color: #fdf;
  background-image: url("2074d703f8b3bed863a70922c91bc82f.png"), repeating-linear-gradient(120deg, rgba(255,255,255,0.1), rgba(255,255,255,0.1) 5px, transparent 10px, transparent 60px), repeating-linear-gradient(60deg, rgba(255,255,255,0.1), rgba(255,255,255,0.1) 5px, transparent 5px, transparent 60px), linear-gradient(60deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.1)), linear-gradient(120deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.1));
  background-size: contain;
  background-clip: content-box;
  background-position: center center;
  background-repeat: no-repeat;
}
.poker-card .avatar {
  width: 80%;
  padding-top: 80%;
  box-sizing: border-box;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  height: 0;
}
.poker-card .avatar .inner-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  border: 3px solid #fdf;
  border-radius: 100%;
}
.poker-card .uname {
  line-height: 16px;
  height: 32px;
  overflow: hidden;
  max-width: 80%;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  color: #888;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@-moz-keyframes win {
  0% {
    transform: rotateY(180deg) rotateZ(0);
  }
  5% {
    transform: rotateY(180deg) rotateZ(8deg);
  }
  10% {
    transform: rotateY(180deg) rotateZ(-8deg);
  }
  15% {
    transform: rotateY(180deg) rotateZ(4deg);
  }
  20% {
    transform: rotateY(180deg) rotateZ(-4deg);
  }
  25% {
    transform: rotateY(180deg) rotateZ(2deg);
  }
  30% {
    transform: rotateY(180deg) rotateZ(-2deg);
  }
  35% {
    transform: rotateY(180deg) rotateZ(0);
  }
  100% {
    transform: rotateY(180deg) rotateZ(0);
  }
}
@-webkit-keyframes win {
  0% {
    transform: rotateY(180deg) rotateZ(0);
  }
  5% {
    transform: rotateY(180deg) rotateZ(8deg);
  }
  10% {
    transform: rotateY(180deg) rotateZ(-8deg);
  }
  15% {
    transform: rotateY(180deg) rotateZ(4deg);
  }
  20% {
    transform: rotateY(180deg) rotateZ(-4deg);
  }
  25% {
    transform: rotateY(180deg) rotateZ(2deg);
  }
  30% {
    transform: rotateY(180deg) rotateZ(-2deg);
  }
  35% {
    transform: rotateY(180deg) rotateZ(0);
  }
  100% {
    transform: rotateY(180deg) rotateZ(0);
  }
}
@-o-keyframes win {
  0% {
    transform: rotateY(180deg) rotateZ(0);
  }
  5% {
    transform: rotateY(180deg) rotateZ(8deg);
  }
  10% {
    transform: rotateY(180deg) rotateZ(-8deg);
  }
  15% {
    transform: rotateY(180deg) rotateZ(4deg);
  }
  20% {
    transform: rotateY(180deg) rotateZ(-4deg);
  }
  25% {
    transform: rotateY(180deg) rotateZ(2deg);
  }
  30% {
    transform: rotateY(180deg) rotateZ(-2deg);
  }
  35% {
    transform: rotateY(180deg) rotateZ(0);
  }
  100% {
    transform: rotateY(180deg) rotateZ(0);
  }
}
@keyframes win {
  0% {
    transform: rotateY(180deg) rotateZ(0);
  }
  5% {
    transform: rotateY(180deg) rotateZ(8deg);
  }
  10% {
    transform: rotateY(180deg) rotateZ(-8deg);
  }
  15% {
    transform: rotateY(180deg) rotateZ(4deg);
  }
  20% {
    transform: rotateY(180deg) rotateZ(-4deg);
  }
  25% {
    transform: rotateY(180deg) rotateZ(2deg);
  }
  30% {
    transform: rotateY(180deg) rotateZ(-2deg);
  }
  35% {
    transform: rotateY(180deg) rotateZ(0);
  }
  100% {
    transform: rotateY(180deg) rotateZ(0);
  }
}
